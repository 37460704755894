import { render, staticRenderFns } from "./HeaderProfile.vue?vue&type=template&id=162c6f4b&scoped=true&"
import script from "./HeaderProfile.vue?vue&type=script&lang=ts&"
export * from "./HeaderProfile.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "162c6f4b",
  null
  
)

export default component.exports